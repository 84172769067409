import { useState, useEffect } from 'react';

export class FetchError extends Error {
  status: number;
  constructor(status:number) {
    super("Status was " + status);
    this.status = status;
  }
}

type Entry = {
  promise?: Promise<any>,
  data?: any,
  error?: any
}
const cache : Record<string,Entry> = {};

const fetchDataWithCache = (url:string) => {
  let existing = cache[url];

  if (existing && existing.promise) {
    return existing.promise;
  }
  if (existing && existing.data) {
    return Promise.resolve(existing.data);
  }

  const promise = fetch(url).then(function(resp) {
    if (resp.ok) {
      return resp.json();
    } else {

      throw new FetchError(resp.status);
    }
  }).then(function(data) {
    cache[url].data = data;
    return data;
  }).catch(function(err:Error) {
    cache[url] = {
      error: err
    }
    throw err;
  });

  cache[url] = {
    promise
  };
  
  return promise;
}


export function useFetchedData<T>(baseUrl?:string, queryParams:Record<string,string> = {}) {
  const [loading, setLoading] = useState(baseUrl ? true : false);
  const [data, setData] = useState<T>();
  const [error, setError] = useState<Error|null>(null);

  const url = baseUrl && new URL(baseUrl);
  if (url) {
    Object.keys(queryParams).forEach(key => url.searchParams.append(key, queryParams[key]));
  }
  const urlString = url?.toString();

  useEffect(() => {
    if (urlString) {
      const fetchData = async() => {
        setLoading(true);
        try {
          const data = await fetchDataWithCache(urlString);
          setData(data);
          setLoading(false);
        } catch (error) {
          setError(error as Error);
          setLoading(false);
        }
      }
      fetchData();
    } else {
      setData(undefined);
      setError(null);
    }
  }, [urlString]);
  
  return { loading, data, error };
};