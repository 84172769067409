import { useEffect, useState } from "react";

const breakpoints = {
  xl: '(min-width: 1200px)',
  lg: '(min-width: 1024px)',
  md: '(min-width: 768px)',
  sm: '(min-width: 576px)',
  xs: '(min-width: 400px)'
};


export const useMediaQuery = () => {
  const [matchedBreakpoint, setMatchedBreakpoint] = useState<string | null | undefined>(undefined);


  useEffect(() => {
    const handleMediaQueryChange = () => {
      const matched = Object.entries(breakpoints).find(
        ([_, query]) => window.matchMedia(query).matches
      );

      setMatchedBreakpoint(matched ? matched[0] : null);
    };

    handleMediaQueryChange();

    window.addEventListener('resize', handleMediaQueryChange);

    return () => {
      window.removeEventListener('resize', handleMediaQueryChange);
    };
  }, [])

  return matchedBreakpoint;
}