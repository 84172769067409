import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Header from './components/Header/Header';
import PageOrganizer from './Pages/PageOrganizer/PageOrganizer';
import { useFetchedData } from './hooks/useFetchedData';
import { IpGeo } from './schema';
import { IpGeoProvider } from './Context/IpGeoContext';
import Footer from './components/Footer/Footer';
import { useEffect } from 'react';
import { LangProvider } from 'cupman-utils';
import { LangFallbackProvider } from 'cupman-utils/lib/LangContext';

const router = createBrowserRouter([
  {
    path: '/',
    element: <RedirectToRoot/> // On root, '/', we want to navigate to cupinvite.com instead of rendering JSX
  },
  {
    path: '/organizer/:organizerId/',
    element: <PageOrganizer />
  },
  {
    path: '/organizer/:organizerId/invitation/:invitationId',
    element: <PageOrganizer />
  },
  {
    path: '/organizer/:organizerId/page/:pageurl',
    element: <PageOrganizer />
  },
  {
    path: '*',
    element: <>Fel sida</>
  }
]);

function App() {

  const ipGeoUrl = 'https://api.ipdata.co/?api-key=39d01b15b0fea8ee964ec2289d604b44b86c938bb115727fa5e35fa9'
  const geoPosition = useFetchedData<IpGeo>(ipGeoUrl);

  return (
    <LangProvider lang={(window as any).cupinvite_lang}>
      <LangFallbackProvider fallback={"en"}>
        <div className="App">
          <IpGeoProvider data={geoPosition.data}>
            <RouterProvider router={router}/>
            
          </IpGeoProvider>
        </div>
        <Footer/>
      </LangFallbackProvider>
    </LangProvider>
  );
}

export default App;




function RedirectToRoot() {

  useEffect(() => {
    window.location.replace('https://www.cupinvite.com');
  }, []);

  return null;
}