import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { SuperinviteEvent} from "../../schema";
import styles from './EventCard.module.scss'; 
import { faEnvelope, faMobileNotch} from "@fortawesome/pro-regular-svg-icons";
import { useTranslations } from "cupman-utils";
import ButtonLink from "../ButtonLink/ButtonLink";
import { useFormatDate } from "cupman-utils/lib/LangContext";
import classNames from "classnames";
import { useEffect, useRef, useState } from "react";
import { useClickOutside } from "../../hooks/useClickOutside";
import { CSSTransition } from "react-transition-group"
import CardImageTop from "./CardImageTop";
import CardHeaderTagAndOrganizer from "./CardHeaderAndTag";
import LocationAndDIstance from "./LocationAndDIstance";

type Props = { 
  event: SuperinviteEvent | undefined,
  organizerId?: string,
  transitionTime: number
}

export default function ListInvitationCard ({event, organizerId, transitionTime} : Props) {

  const uuid = crypto.randomUUID()
  
  const formatDate = useFormatDate();
  const T = useTranslations('cupinvite');

  const nodeRef = useRef<null | HTMLDivElement>(null);
  const cardRef = useRef<null | HTMLButtonElement>(null);
  const [opened, setOpened] = useState(false);

  useClickOutside({
    ref: cardRef,
    callback: () => setOpened(false)
  })
  const [cardInitHeight, setCardInitHeight] = useState<string | null>(null)


  useEffect(() => {
    if(cardRef.current) {
      setCardInitHeight(`${cardRef.current.clientHeight}px`)
    }
  }, [cardRef])

  const showContact = event && (event.contact_person || event.contact_person_email || event.contact_person_number);
  const iconToShow = event && event.contact_person_number
    ? faMobileNotch
    : faEnvelope
  
  const preTrimmedShowUrl = event && event.website
    .replace(/(^\w+:|^)\/\//, '')
    .replace('www.', '');
  const trimmedShowUrl = preTrimmedShowUrl && preTrimmedShowUrl.substring(0, preTrimmedShowUrl.indexOf('/'));

  const [zIndex, setZindex] = useState(1);

  const trimmedCutText = event?.description
    .replace(/<\/?[^>]+(>|$)/g, " ")
    .replace(/\s+/g, " ")
    .replace('-&nbsp;', '')
    .trim()
    .substring(0, 80) + '...';
 

  return event 
    ? <div style={{
        '--card-height' : cardInitHeight,
        '--z-index' : zIndex
      } as any} className={styles.card_fill}>
          <button 
            className={classNames('no_select', styles.card_container, opened && styles.opened)} 
            onClick={() => setOpened(!opened)} 
            ref={cardRef}
            aria-expanded={opened}
            aria-controls={uuid}
          >
            
          <CardImageTop 
            backgroundImage={event.heroImageUrl} 
            eventImage={event.logo}
            color={event.organizerColor}
            startTime={event.start_date}
            endTime={event.end_date}
            eventName={event.name}
          />
          <CardHeaderTagAndOrganizer 
            title={event.name} 
            opened={opened} 
            organizer={event.club_name}
            // Type can be sent here
          />
          <div className={styles.card_content_visible}>
            <div style={{height: 'calc(52px - 1rem)', marginTop: '1rem', fontSize: '.9rem', lineHeight: '1.3', fontStyle: 'italic'}}>
              {trimmedCutText}
            </div>
            <LocationAndDIstance />
            <div>
              <strong>{T('Boka innan:')}</strong> {formatDate(Date.parse(event.reg_end_date), 'd MMMM, yyyy')}
            </div>
          </div>


          <CSSTransition
            in={opened}
            timeout={transitionTime}
            nodeRef={nodeRef}
            classNames={{
              enter: styles.enter,
              enterActive: styles.enterActive,
              enterDone: styles.enterDone,
              exit: styles.exit,
              exitActive: styles.exitActive,
              exitDone: styles.exitDone
            }}
            onExited={() => setZindex(1)}
            onExiting={() => setZindex(3)}
            onEntering={() => setZindex(4)}
          >
            <div 
              className={styles.card_content_invisible} 
              ref={nodeRef} 
              id={uuid}
            >
              <div className={styles.order_button_wrapper}>
                <ButtonLink 
                  to={`/organizer/${organizerId}/invitation/${event.public_code}`} 
                  icon={faEnvelope} 
                  block 
                  noScale
                  centerContent
                >
                  {T('Visa inbjudan')}
                </ButtonLink>
              </div>
              {showContact && <>
                <h3 className={styles.card_section_header}>
                  <FontAwesomeIcon icon={iconToShow}/> {T('Kontakt')}
                </h3>
                <div className={styles.contact_person}>
                  {event.contact_person && <div aria-description={T('Namn')}>
                    <span>{event.contact_person}</span>
                  </div>}
                  {event.contact_person_number && <div aria-description={T('Telefon')}>
                    <a href={`tel: ${event.contact_person_number}`} className={'truncate body_link'}>{event.contact_person_number}</a>
                  </div>}
                  {event.contact_person_email && <div aria-description={T('Epost')}>
                    <a href={`mailto: ${event.contact_person_email}`} className={'truncate body_link'}>{event.contact_person_email}</a>
                  </div>}
                  {event.website && <div aria-description={T('Epost')}>
                    <a href={event.website} className={'truncate body_link'}>{trimmedShowUrl}</a>
                  </div>}
                </div>
              </>}
            </div>
          </CSSTransition>
        </button>
      </div>
    : <></>
}