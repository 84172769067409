
import styles from './InvitationInformation.module.scss'; 
import { useTranslations } from "cupman-utils";
import InvitationCard from "../InvitationCard/InvitationCard";
import ClubInformationContent from "./ClubInformationContent";
import ButtonLink from "../ButtonLink/ButtonLink";
import { faCartPlus } from "@fortawesome/pro-regular-svg-icons";
import { SuperinviteEvent, } from '../../schema';

type Props = {
  invitation: SuperinviteEvent,
  actionButtons?: React.ReactNode[]
}

export default function InvitationInformation ({invitation, actionButtons} : Props) {

  const T = useTranslations('cupinvite')

  return <div className={styles.outer_guide_container}>
    <div className={styles.inner_guide_container}>
      <div className={styles.desktop_card_container}>
        <InvitationCard invitation={invitation} />
      </div>
      <div className={styles.invitation_body_text}>
        <div className={styles.mobile_card_container}>
          <InvitationCard invitation={invitation} />
        </div>
        <ClubInformationContent invitation={invitation} actionButtons={actionButtons} />
        <h1 className={styles.main_header}>{invitation.name}</h1>
        <div className={styles.body_button}>
          <ButtonLink to={invitation.url+'/step/products'} icon={faCartPlus} noScale>
            {T('Välj produkter')}
          </ButtonLink>
        </div>
        <div dangerouslySetInnerHTML={{ __html: invitation.description }} className={styles.text_html} />
        <h2>{T('Bra att veta')}</h2>
        <div dangerouslySetInnerHTML={{ __html: invitation.practical }} className={styles.text_html} />
      </div>
    </div>
    <div className={styles.mobile_order_button_container}>
      <ButtonLink to={invitation.url+'/step/products'} block centerContent noScale icon={faCartPlus} className={styles.order_button_mobile}>{T('Välj produkter')}</ButtonLink>
    </div>
  </div>
}