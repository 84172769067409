import { Link } from "react-router-dom";
import styles from './ButtonLink.module.scss'; 
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";

type Props = {
  children: React.ReactNode,
  to: string,
  icon?: IconDefinition,
  ellipsis?: boolean,
  block?: boolean,
  noScale?: boolean,
  centerContent?: boolean,
  className?: string,
  bgColor?: string,
  hoverBgColor?: string,
  color?: string,
  onClick?: (e:React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

export default function ButtonLink ({children, to, icon, ellipsis, block, noScale, centerContent, className, bgColor: _bgColor, hoverBgColor: _hoverBgColor, color: _color, onClick} : Props) {

  const color = _color ? _color : 'white';
  const bgColor = _bgColor ? _bgColor : 'rgb(var(--color-ci-green))';
  const hoverBgColor = _hoverBgColor ? _hoverBgColor : 'rgb(var(--color-ci-green-lighter))';


  return <Link 
      onClick={(e:React.MouseEvent<HTMLAnchorElement, MouseEvent>) => onClick && onClick(e)}
      to={to}
      className={classNames(
        styles.button_link, 
        block && styles.display_block, 
        ellipsis && 'truncate',
        noScale && styles.no_scale,
        centerContent && styles.center,
        className && className
      )} 
      style={{
        '--bg-color' : bgColor,
        '--hover-bg-color' : hoverBgColor,
        '--color' : color
      } as any}
    >
    <div style={{maxWidth: '100%'}}>
      {icon && <span className={styles.icon_container}>
        <FontAwesomeIcon icon={icon}/>
      </span>}
      <span className={classNames(styles.label, !icon && styles.no_hover_padding)}>{children}</span>
    </div>
  </Link>
}
