import { useTranslations } from "cupman-utils";
import { useIpGeo } from "../Context/IpGeoContext";

type Props = {
  latitude: number | string,
  longitude: number | string
}

export function useGetDistanceToInKmString() {

  const ipGeo = useIpGeo();
  const T = useTranslations('cupinvite')

  function deg2rad(deg:number) {
    return deg * (Math.PI/180)
  }

  function getDistanceTo({latitude: _latitude, longitude: _longitude} : Props) {

    if(ipGeo.data) {
      const latitude = typeof _latitude === 'string'
      ? parseInt(_latitude)
      : _latitude
  
      const longitude = typeof _longitude === 'string'
        ? parseInt(_longitude)
        : _longitude


      const R = 6371; // Radius of the earth in km
      const dLat = deg2rad(ipGeo.data.latitude - latitude);
      const dLon = deg2rad(ipGeo.data.longitude - longitude);

      const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
		    Math.cos(deg2rad(latitude)) * Math.cos(deg2rad(ipGeo.data.latitude)) * 
		    Math.sin(dLon/2) * Math.sin(dLon/2);

      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a)); 
      const d = R * c; // Distance in km

      return T('X km från dig', Math.round(d));
    }

    return ''

  }

  return ({latitude, longitude} : Props) => getDistanceTo({latitude, longitude})
}