import styles from './CardsList.module.scss';

type Props = {
  children: React.ReactNode[] | React.ReactNode,
  title?: string
}

export default function CardsList ({children, title} : Props) {
  return <>
    {title && <h2 className={styles.title}>
      <span>{title}</span>
    </h2>}
    <div className={styles.cards_list_container}>
      {children}
    </div>
  </>
}