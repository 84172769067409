import { createContext, useContext, useEffect, useState } from "react";
import { IpGeo } from "../schema";

type IpGeoContextType = {
  data: IpGeo | null;
  setNewCoordinates: (coordinates: { longitude: number; latitude: number }) => void;
};

const IpGeoContext = createContext<IpGeoContextType | null>(null);

export const IpGeoProvider: React.FC<{data?:IpGeo; children:React.ReactNode}> = ({ data, children }) => {

  const [geoData, setGeoData] = useState<IpGeo | null>(data || null);

  useEffect(() => {
    if (data) {
      setGeoData(data)
    }
  }, [data])

  const setNewCoordinates = ({longitude, latitude}: {longitude: number; latitude: number}) => {
    if (geoData) {
      setGeoData({
        ...geoData,
        longitude,
        latitude
      })
    }
  }

  return <IpGeoContext.Provider value={{ data: geoData, setNewCoordinates }}>
    {children}
  </IpGeoContext.Provider>
}

export const useIpGeo = () => {
  const context = useContext(IpGeoContext);
  if (!context) {
    throw new Error('useIpGeo is outside Provider');
  }
  return context;
};