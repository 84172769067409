import { useTranslations } from 'cupman-utils';
import styles from './Footer.module.scss';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight } from '@fortawesome/pro-regular-svg-icons';

import invitesports_color from '../../images/invitesports_color.png';

export default function Footer () {

  const T = useTranslations('cupinvite')

  /* 
  
  På cupinvite.com hittar du alla dina turneringar, cuper samt inbjudningar till träningsläger, mm - på ett och samma ställe. Här kan du kan söka efter relevanta turneringar, cuper och inbjudningar inom sport och föreningsliv som passar dig.

  'Cup Invite samlar cuper, turneringar, inbjudningar till träningsläger och mer inom sport och föreningslivet. Cup Invite är för dig som är lagledare, förälder eller annan intresserad som söker aktiviteter och turneringar.'
  
  */

  return <footer className={styles.footer}>
    {/* 
    <div className="max-75">
      <FooterSpot 
        logoUrl="https://static.cupmanager.net/images/cupinvite/svg/cupmanager.svg?v=2"
        imageUrl="https://static.cupmanager.net/images/cupinvite/img/basketball.webp"
        brandName="Cup Manager"
        text={T('Cup Manager hjälper dig som organiserar cuper och turneringar, så att du enkelt kan få en fantastisk upplevelse för dig och dina deltagare.')}
        linkTo="https://www.cupmanager.net/"
        logoWidthPercentage={70}
      />
      <FooterSpot 
        logoUrl="https://static.cupmanager.net/images/cupinvite/svg/superinvite.svg?v=1"
        imageUrl="https://static.cupmanager.net/images/cupinvite/img/icehockey.webp"
        brandName="Super Invite"
        text={T('Superinvite är den kompletta lösningen för alla klubbar och föreningar som vill ha en enklare vardag med betalningar och medlemshantering.')}
        logoWidthPercentage={60}
        linkTo="https://superinvite.com/"
        imagePosition='right'
      />
    </div>
  */}


    
    <div className={styles.contact_info}>
      <div className="max-75">

        <div className={styles.contact_info_inner}>
          <div className={styles.footer_side}>
            {/* <button className={styles.login_button}>
              {T('Redigera sida')}
            </button> */}
          </div>
          <div className={styles.footer_main}>
            <img src={invitesports_color} alt={T('CupInvite')} className={styles.footer_logo}/>
            {/*<p dangerouslySetInnerHTML={{ __html: T('På <a href="https://cupinvite.com">cupinvite</a> hittar du alla dina turneringar, cuper, träningsläger, mm - på ett och samma ställe. Här kan du kan söka efter relevanta turneringar, cuper och inbjudningar inom sport och föreningsliv som passar dig.')}} />*/}
            <p dangerouslySetInnerHTML={{ __html: T('Invite Sports står bakom Cup Manager och Superinvite som hjälper idrottsföreningar att arrangera cuper och träningsläger m.m.')}} />
            <div className={styles.products}>
              <img src="https://static.cupmanager.net/thimmayya/images/cupmanager_dark.svg" alt="Cup Manager" className={styles.cupmanager}/>
              <img src="https://static.cupmanager.net/thimmayya/images/superinvite.svg" alt="Super Invite" />
              <img src="https://static.cupmanager.net/thimmayya/images/cupinvite.svg" alt="Cup Invite" />
            </div>
          </div>
          <div className={styles.footer_side}></div>
        </div>
      </div>
    </div>
  </footer>
}

type Props = {
  logoUrl: string,
  brandName: string,
  imageUrl: string,
  text: string,
  imagePosition?: 'left' | 'right',
  logoWidthPercentage: number,
  linkTo: string
}

function FooterSpot ({logoUrl, brandName, imageUrl, text, imagePosition, logoWidthPercentage, linkTo} : Props) {
  
  const T = useTranslations('cupinvite');

  return <article className={classNames(styles.article_container, imagePosition === 'right' ? styles.right : styles.left)}>
    <div className={styles.img_wrapper}>
    <div className={styles.arrow_container}></div>
      <div className={styles.inner_img_wrapper}>
        <img src={imageUrl} alt={brandName} />
        
      </div>
    </div>

    <div className={styles.content}>
      <div className={styles.content_inner}>
        <header style={{
          '--logo-width-percentage' : `${logoWidthPercentage}%`,
          '--logo-width-px' : `${logoWidthPercentage * 4}px`
          } as any} className={styles.logo_container}>
          <img src={logoUrl} alt={brandName} />
        </header>
        <p>{text}</p>
        <a href={linkTo} className={styles.button_link}>
          {T('Gå till X', brandName)} <FontAwesomeIcon icon={faAngleRight} className={styles.arrow} />
        </a>
      </div>
    </div>
  </article>
}
