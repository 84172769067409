import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './EventTypeTag.module.scss';
import { faBooks, faCampfire, faDumbbell, faEnvelope, faGraduationCap, faHeartbeat, faHotel, faRunning, faTrophy } from '@fortawesome/pro-solid-svg-icons';
import { EventType } from '../../schema';
import { useTranslations } from 'cupman-utils';

type Props = {
  type?: EventType
}

export default function EventTypeTag ({type} : Props) {

  const T = useTranslations('cupinvite')

  const eventType = () => {
    if(type === 'cardio') return {
      icon: faRunning,
      text: T('Konditionsträning')
    }
    if(type === 'academy') return {
      icon: faGraduationCap,
      text: T('Akademi')
    }
    if(type === 'clinic') return {
      icon: faHeartbeat,
      text: T('Klinik')
    }
    if(type === 'strength') return {
      icon: faDumbbell,
      text: T('Styrketräning')
    }
    if(type === 'camp') return {
      icon: faCampfire,
      text: T('Läger')
    }
    if(type === 'theory') return {
      icon: faBooks,
      text: T('Teori')
    }
    if(type === 'lodging') return {
      icon: faHotel,
      text: T('Boende')
    }
    if(type === 'cup') return {
      icon: faTrophy,
      text: T('Turnering')
    }
    return {
      icon: faEnvelope,
      text: T('Inbjudan')
    }
  }

  return <span className={styles.tag} style={{'--bg-color' : type === 'cup' ? 'var(--color-tag-blue)' : 'var(--color-tag-purple)'} as any}>
    <FontAwesomeIcon icon={eventType().icon} /> {eventType().text}
  </span>
}