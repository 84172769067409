import { useParams } from 'react-router-dom';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { hexToRgb, rgbToString } from '../../utils/colors';
import styles from './TwistedHeroTopTwo.module.scss'; 
import { Organizer } from '../../schema';
import React, { useEffect, useRef, useState } from 'react';
import OrganizerLogo from '../OrganizerLogo/OrganizerLogo';
import { CSSTransition } from "react-transition-group"
import classNames from 'classnames';

type Props = {
  organizer: Organizer | undefined,
  title: string,
  actionButtons: React.ReactNode[] | undefined
}

export default function TwistedHeroTopTwo ({organizer, actionButtons, title} : Props) {

  const extraLargeHeader = !organizer?.logoImageUrl && !organizer?.heroContent;


  const {organizerId, invitationId, pageurl} = useParams<{organizerId: string, invitationId: string, pageurl:string}>();
  const isOrganizerPage = !invitationId && !pageurl;

  const matchedBreakpoint = useMediaQuery();
  const smallSizes =  matchedBreakpoint === null || 
                      matchedBreakpoint === 'sm' || 
                      matchedBreakpoint === 'xs'

  const initialColorPadding = smallSizes ? 40 : 20;
  const [colorPadding, setColorPadding] = useState(initialColorPadding);
  const [opacity, setOpacity] = useState(1);
  const [translateYValue, setTraslateYValue] = useState(0)
                      
  useEffect(() => {
    setColorPadding(smallSizes ? 40 : 20);

    const handleScroll = () => {
      if(smallSizes) {
        const scrollY = window.scrollY;
        const speedFactor = smallSizes ? .3 : 1;
        const parallaxValue = scrollY * speedFactor;
        setTraslateYValue(parallaxValue)

        const maxScroll = 1200;
        const opacityValue = 1 - Math.min(scrollY / maxScroll, 1);
        setOpacity(opacityValue);
      } else {
        setTraslateYValue(0)
        setOpacity(1);
      }
      
    }
    handleScroll();

    window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      }

  }, [smallSizes])
  

  const color = organizer?.color ? organizer.color : '#808080';
  const minHeight = 0;
  
  const animationTimeOut = 300;
  const nodeRef = useRef<null | HTMLDivElement>(null);

  const isNotScrolled = window.scrollY === 0;

  
  return <div 
    className={classNames(styles.hero_container)}
    style={{
      '--color-solid' : color,
      '--color-alpha' : rgbToString(hexToRgb(color), .2),
      '--min-height' : `${minHeight}px`,
      '--brand-color-space' : `${colorPadding}px`
    } as any}
  >
    <div 
      className={styles.image_container}
      style={{
        backgroundImage:  organizer ? `url(${organizer.heroImageUrl})` : "url('//static.cupmanager.net/images/cupinvite/img/organizer.webp')"
      }}
    >
      <div className={classNames(styles.hero_content)} style={{'--timeout' : animationTimeOut+'ms'} as any}>
        <>
          {isNotScrolled
          ? organizer?.logoImageUrl && <CSSTransition
              in={isOrganizerPage}
              timeout={animationTimeOut}
              nodeRef={nodeRef}
              classNames={{
                enter: styles.enter,
                enterActive: styles.enterActive,
                enterDone: styles.enterDone,
                exit: styles.exit,
                exitActive: styles.exitActive,
                exitDone: styles.exitDone
              }}
            >
              <div ref={nodeRef} className={classNames(styles.logo_transition_container, isOrganizerPage && styles.organizer)}>
                <OrganizerLogo linkTo={`/organizer/${organizer.id}`} logoUrl={organizer?.logoImageUrl ? organizer.logoImageUrl : undefined} name={organizer ? organizer.name : ''} sizePx={180} />
              </div>
            </CSSTransition>
          : organizer?.logoImageUrl && <div className={classNames(styles.logo_transition_container, isOrganizerPage && styles.organizer)}>
              <OrganizerLogo  linkTo={`/organizer/${organizer.id}`} logoUrl={organizer.logoImageUrl} name={organizer ? organizer.name : ''} sizePx={180} />
            </div>
          }
          <div className={styles.hero_content_text}>
            <h1 className={classNames(extraLargeHeader && styles.extra_large)}>{title}</h1>
            {isOrganizerPage && organizer?.heroContent && <p dangerouslySetInnerHTML={{ __html: organizer.heroContent}} className={styles.hero_content_text_inner}></p> }
            {actionButtons && <div className={styles.buttons}>
              {actionButtons}
            </div>}
          </div>
        </>
      </div>
    </div>
    <div className={styles.brand_color}
      style={{
        bottom: `-${colorPadding}px`,
        paddingBottom: `${colorPadding}px`,
        marginBottom: `40px`,
        opacity: opacity,
        //transform: `translateY(${translateYValue}px)`
      }}
    ></div>
  </div>
}