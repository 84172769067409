import { useClickOutside } from "../../hooks/useClickOutside";
import { CupEvent, EventType, PortalCategory } from "../../schema";
import styles from './EventCard.module.scss'; 
import { useTranslations } from "cupman-utils";
import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import CardImageTop from "./CardImageTop";
import CardHeaderTagAndOrganizer from "./CardHeaderAndTag";
import LocationAndDIstance from "./LocationAndDIstance";
import { CSSTransition } from "react-transition-group";
import ButtonLink from "../ButtonLink/ButtonLink";
import { faExternalLink } from "@fortawesome/pro-regular-svg-icons";
import { groupBy } from "../../utils/groupBy";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMars, faVenus, faVenusMars } from "@fortawesome/pro-solid-svg-icons";
import { dev } from "../../utils/devMode";
import { joinAnd, capitalize } from "../../utils/stringHandling";

type Props = {
  event: CupEvent,
  transitionTime: number
}

export default function ListCupCard ({event, transitionTime} : Props) {

  const T = useTranslations('cupinvite')

  const cardExpansionRef = useRef<null | HTMLDivElement>(null);
  const classesExpansionRef = useRef<null | HTMLDivElement>(null);
  const cardRef = useRef<null | HTMLButtonElement>(null);
  

  const [zIndex, setZindex] = useState(1);
  const [cardInitHeight, setCardInitHeight] = useState<string | null>(null);
  const [cardExpanded, setCardExpanded] = useState(false);
  const [classesExpanded, setClassesExpanded] = useState(false);

  useEffect(() => {
    if(cardRef.current) {
      setCardInitHeight(`${cardRef.current.clientHeight}px`)
    }
  }, [cardRef])

  useClickOutside({
    ref: cardRef,
    callback: () => {
      setCardExpanded(false)
    }
  })

  const categoriesExpensionButtonClass = 'classes-expand-button';

  const toggleCategories = event.categories.length > 14;

  const ageSpan = {
    min: Math.min(...event.categories.map(cat => cat.age)),
    max: Math.max(...event.categories.map(cat => cat.age))
  }

  const categoriesMap = groupBy(event.categories, (category) => category.gender);
  const categoriesKeys = Object.keys(categoriesMap);

  const categoryConclusionTextArr = Object.entries(categoriesMap).map(arr => {

    const ageSpan = {
      min: Math.min(...arr[1].map(cat => cat.age)),
      max: Math.max(...arr[1].map(cat => cat.age))
    }

    const genderString = arr[0] === 'women'
      ? 'flickor'
      : arr[0] === 'men'
        ? 'pojkar'
        : 'mixed'

    return T(genderString + ' X-Y', ageSpan.min, ageSpan.max )
  })

  const categoryConclusionText = capitalize(joinAnd(categoryConclusionTextArr, T('och')));

  const uuid = crypto.randomUUID()

  return <div style={{
    '--card-height' : cardInitHeight,
    '--z-index' : zIndex
  } as any} className={styles.card_fill}>
    <article 
      role="button" 
      tabIndex={0} 
      className={classNames('no_select', styles.card_container, cardExpanded && styles.opened)}
      onKeyDown={(e:React.KeyboardEvent) => {
        if(e.key === 'Escape') {
          setCardExpanded(false);
        }
        if(e.key === 'Enter') {
          setCardExpanded(!cardExpanded);
        }
      }}
      onClick={() => {
        if(document.activeElement?.classList[0] !== categoriesExpensionButtonClass) {
          setCardExpanded(!cardExpanded);
        }
      }}
      ref={cardRef}
      aria-expanded={cardExpanded}
      aria-controls={uuid}
    >
      <CardImageTop 
        backgroundImage={event.featuredImageUrl} 
        eventImage={event.imageUrl}
        color={event.color}
        startTime={event.startDate}
        endTime={event.endDate}
        eventName={event.name}
      />
      <div className={styles.price}>
        {T('Från X Y', event.lowestPrice, event.currency)} {event.hasFreeCategories && <> • {T('Har gratisklasser')}</>}
      </div>
      <CardHeaderTagAndOrganizer 
        title={event.name} 
        opened={cardExpanded} 
        organizer={event.organizerName}
        type='cup'
      />
      <div className={styles.card_content_visible}>
        {event.evaluation 
          ? <div className={styles.evaluation_overview}>
            <img src={event.evaluation.starsImage} alt={event.evaluation.stars}/> 
            {cardExpanded 
              ? <a href={event.evaluation.diplomaUrl} target="_blank" rel="norefferer noreferrer">
                ({event.evaluation.stars}) {T('X utvärderingar', event.evaluation.count)}
              </a>
              : <span>
                ({event.evaluation.stars}) {T('X utvärderingar', event.evaluation.count)}
              </span>
            }
          </div>
          : <div className={classNames(styles.evaluation_overview, styles.disabled)}>
            <img src='https://static.cupmanager.net/uploads/evaluation_inline,none.png' alt='0'/>
            <span>{T('Ingen utvärdering')}</span>
          </div>
        }
        <LocationAndDIstance address={event.address} />
        <div className={classNames(!cardExpanded && "truncate")}>
          <FontAwesomeIcon icon={faVenusMars}/> {categoryConclusionText}
        </div>
      </div>

      <CSSTransition
        in={cardExpanded}
        timeout={transitionTime}
        nodeRef={cardExpansionRef}
        classNames={{
          enter: styles.enter,
          enterActive: styles.enterActive,
          enterDone: styles.enterDone,
          exit: styles.exit,
          exitActive: styles.exitActive,
          exitDone: styles.exitDone
        }}
        onExited={() => setZindex(1)}
        onExiting={() => setZindex(3)}
        onEntering={() => setZindex(4)}
      >
        <div 
          className={styles.card_content_invisible} 
          ref={cardExpansionRef} 
          id={uuid}
        >
          <div className={styles.order_button_wrapper}>
            <ButtonLink 
              to={event.websiteUrl} 
              icon={faExternalLink} 
              block 
              noScale
              centerContent
            >
              {T('Gå till X', event.name)}
            </ButtonLink>
          </div>
          <div>
            <h3 className={styles.classes_header}>{T('Klasser')}</h3>
            {toggleCategories
              ? <>
                <button 
                  className={classNames(categoriesExpensionButtonClass, styles.classes_expand_button)} onClick={() => setClassesExpanded(!classesExpanded)}>
                  {T('Visa X klasser (Y år)', event.categories.length, `${ageSpan.min}-${ageSpan.max}`)}
                </button>
                <CSSTransition
                  in={classesExpanded}
                  timeout={transitionTime}
                  nodeRef={classesExpansionRef}
                  classNames={{
                    enter: styles.enter,
                    enterActive: styles.enterActive,
                    enterDone: styles.enterDone,
                    exit: styles.exit,
                    exitActive: styles.exitActive,
                    exitDone: styles.exitDone
                  }}
                >
                  <div className={styles.class_list} ref={classesExpansionRef}>
                    <Classes categoriesMap={categoriesMap} categoriesKeys={categoriesKeys} />
                  </div>
                </CSSTransition>
              </>
              : <Classes categoriesMap={categoriesMap} categoriesKeys={categoriesKeys} />
            }

            {event.evaluation?.testimonials && <div className={styles.testimonials}>
              <h2 className={styles.classes_header}>{T('Omdömen')}</h2>
              <div className={styles.testimonials_inner}>
                {event.evaluation.testimonials.map((testimonial, i) => {

                  return <div className={styles.testimonial} key={i}>
                    <blockquote>
                      {testimonial.testimonial}
                    </blockquote>
                    <img src={testimonial.starsImage} alt={''+testimonial.stars} />
                    <p>
                      {testimonial.name} {testimonial.club}
                    </p>
                  </div>})}
              </div>
            </div>}
            
          </div>
        </div>
      </CSSTransition>
    </article>
  </div>
}


function Classes ({categoriesMap, categoriesKeys} : {categoriesMap: Record<string, PortalCategory[]>, categoriesKeys: string[]}) {

  const T = useTranslations('cupinvite');
  const gotMixed = categoriesKeys.some(cKey => cKey === 'mixed');
  const gotMen = categoriesKeys.some(cKey => cKey === 'men');
  const gotWomen = categoriesKeys.some(cKey => cKey === 'women');

  return <>
    {gotWomen && <>
      <h4 className={styles.women}>
        {T('Flickor')} <FontAwesomeIcon icon={faVenus}/>
      </h4>
      <Class classes={categoriesMap['women']} />
    </>}
    {gotMen && <>
      <h4 className={styles.men}>
        {T('Pojkar')} <FontAwesomeIcon icon={faMars}/>
      </h4>
      <Class classes={categoriesMap['men']} />
    </>}
    {gotMixed && <>
      <h4 className={styles.mixed}>
        {T('Mixed')} <FontAwesomeIcon icon={faVenusMars}/>
      </h4>
      <Class classes={categoriesMap['mixed']} />
    </>}
  </>
}



function Class ({classes} : {classes: PortalCategory[]}) {

  return <ul className={styles.classes}>
    {classes.sort((a, b) => a.age - b.age).map((cat) => <li key={cat.shortName}>
      {cat.shortName}
    </li>)}
  </ul>
}

function capitatalize(arg0: string) {
  throw new Error("Function not implemented.");
}

