import { Link, useParams } from "react-router-dom";
import { FetchError, useFetchedData } from "../../hooks/useFetchedData";
import TwistedHeroTopTwo from "../../components/TwistedHeroTop/TwistedHeroTopTwo";
import InvitationInformation from "../../components/InvitationBodyContent/InvitationInformation";
import { Card, CupEvent, Organizer, Block, BlockContainer, OrganizerPage } from "../../schema";
import ButtonLinkHero from "../../components/ButtonLink/ButtonLinkHero";
import { useTranslations } from "cupman-utils";
import { stringToNavigationHash } from "../../utils/stringToNavigationHash";
import { faCalendarDays } from "@fortawesome/pro-regular-svg-icons";
import CardsList from "../../components/CardsList/CardsList";
import ListInvitationCard from "../../components/EventCard/ListInvitationCard";
import { useScrollOnBackForward } from "../../hooks/useScrollOnBackForward";
import ListCupCard from "../../components/EventCard/ListCupCard";
import classNames from "classnames";
import styles from './PageOrganizer.module.scss';
import { contrastColor, getContrastColor, hexToRgb, rgbToString } from "../../utils/colors";
import { useEffect } from "react";
import PageLoading from "../../components/Loading/PageLoading";
import PageError from "../PageError/PageError";
import ListOrganizerCard from "../../components/EventCard/ListOrganizerCard";
import Header from "../../components/Header/Header";


export default function PageOrganizer () {
  const transitionTime = 150;
  
  useScrollOnBackForward();

  const T = useTranslations('cupinvite')

  const {organizerId, invitationId, pageurl} = useParams<{organizerId: string, invitationId?: string, pageurl?:string}>()

  const {data:organizer, loading, error} = useFetchedData<Organizer>('https://results.cupmanager.net/rest/newportal/organizer/' + organizerId + '?include=cards');
  const {data:page, loading:pageloading, error:pageerror} = useFetchedData<OrganizerPage>(pageurl && `https://results.cupmanager.net/rest/newportal/organizer/${organizerId}/page/${pageurl}`);

  useEffect(() => {
    const ariaLiveEl = document.getElementById('aria-live');

    if (ariaLiveEl) {
      if(loading) {
        ariaLiveEl.innerHTML = T('Laddar data')
      } else {
        if(invitationId) {
          ariaLiveEl.innerHTML = T('Inbjudan laddad')
        } else {
          ariaLiveEl.innerHTML = T('Eventen laddade')
        }
      }
    }
  }, [loading, T, invitationId])

  useEffect(() => {
    const hash = window.location.hash;

    if(hash) {
      const targetEl = document.querySelector(hash);
      
      setTimeout(() => {
        if (targetEl) {
          targetEl.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100)
    }
  }, [organizer])

  const siEvents = organizer && organizer.cards.filter(event => event.cardType === 'si_event');
  const cmCups = organizer && organizer.cards.filter(event => event.cardType === 'cm_cup') as CupEvent[];

  const event = organizer && organizer.cards.find(event => event.si_event?.public_code === invitationId);

  document.title = organizer 
    ? event 
      ? `${event.si_event?.name} - ${organizer.name}`
      : page 
        ? `${page.title} - ${organizer.name}`
        : organizer.name 
    : 'CupInvite';

  const color = organizer?.color ? organizer.color : '#808080';
  const contrastRationToReach = 3.2;


  const actionButtons = organizer && [
    (invitationId || pageurl) && <ButtonLinkHero 
      to={`/organizer/${organizerId}/`}
      key={'cups'}
      colors={{
        background: rgbToString(contrastColor(hexToRgb(color), contrastRationToReach)),
        color: 'white'
      }}
    >
      {T('Start')}
    </ButtonLinkHero>,

    (!invitationId && !pageurl) && organizer.boxes?.map((box, i) => {

      const hash = `#${stringToNavigationHash(box.title)}-${i+1}`;

      return <ButtonLinkHero 
        key={i} 
        to={`/organizer/${organizerId}/${hash}`}
        colors={{
          background: rgbToString(contrastColor(hexToRgb(color), contrastRationToReach)),
          color: 'white'
        }}
        onClick={(e) => {
          const targetEl = document.querySelector(hash);

          if(targetEl) {
            e.preventDefault();
            const rect = targetEl.getBoundingClientRect();
            const scrollTop = window.scrollY || document.documentElement.scrollTop;
            const targetOffsetTop = rect.top + scrollTop;
            window.scrollTo({
                top: targetOffsetTop - 65, // Subtracting 50 pixels for the offset
                behavior: 'smooth'
            });
        }
        }}
      >
        {box.title}
      </ButtonLinkHero>
    }),
    organizer.actionButtons && organizer.actionButtons.map((btn, i) => <ButtonLinkHero 
      colors={{
        background: rgbToString(contrastColor(hexToRgb(color), contrastRationToReach)),
        color: 'white'
      }}
      to={`/organizer/${organizerId}/page/${btn.url}`}
      key={`action${i}`}
      >
        {btn.label}
    </ButtonLinkHero>)
  ].filter(button => !!button).flat();


  var contentToShow; 
  if (invitationId) {
    contentToShow = event?.cardType === 'si_event' 
      ? <InvitationInformation invitation={event.si_event!} actionButtons={actionButtons}/>
      : <div className={styles.no_data}>
        <div>
        <h2>{T('Detta event verkar inte finnas')} {organizer && T('hos X', organizer.name)}</h2>
          <p>{T('Kan det vara så att du fått fel länk? Eller att eventet redan varit? Kontakta oss gärna om du har frågor eller funderingar.')}</p>
        <p>
          {T('Hälsningar,')}<br />
          <strong><i>{organizer?.name}</i></strong>
        </p>
        </div>
      </div>
  } else if (pageurl) {
    contentToShow = <div className={styles.page_padding}>
      <div className={classNames('max-50', styles.container)} style={{
        '--transition-time' : `${transitionTime}ms`
      } as any}>
        <>
          {page && renderBlockContainers(page.content)} 
        </>
      </div>
    </div>
  } else {
    contentToShow = <>
      {organizer && organizer.cards.length > 0 ? 
        <div className={styles.page_padding}>
          <div className={classNames('max-50', styles.container)} style={{
            '--transition-time' : `${transitionTime}ms`
          } as any}>
            <>
              {organizer.startPageContent.length>0 && <div>
                {renderBlockContainers(organizer.startPageContent)} 
              </div>}
              {cmCups && cmCups.length > 0 && <CardsList title={T('Turneringar')}>
                {cmCups.map((event,i) => <ListCupCard 
                  event={event}
                  key={`cup-${i}`}
                  transitionTime={transitionTime}
                />)}
              </CardsList>}
              {siEvents && siEvents.length > 0 && <CardsList title={T('Inbjudningar')}>
                {siEvents.map((event,i) => {
                  return <ListInvitationCard 
                    event={event.si_event!} 
                    organizerId={organizerId} 
                    key={`event-${i}`}
                    transitionTime={transitionTime}
                  />}
                )}
              </CardsList>}
            </>
          </div>
        </div>
      : 
      <div className={styles.no_data}>
        <div>
          <h2>
            {T('Just nu finns inga event')} {organizer && T('hos X', organizer.name)}
          </h2>
          <p>
            {T('Kom gärna tillbaka senare igen. Om du har frågor eller funderingar så kontakta gärna oss.')}
          </p>
          <p>
            {T('Hälsningar,')}<br />
            <strong><i>{organizer ? organizer.name : ''}</i></strong>
          </p>
        </div>
      </div>}
      {organizer?.boxes && <div className={styles.info_boxes}>
        {organizer.boxes.map((infoBox, i) => {

          const isEven = i % 2 === 0;
          const hash = `${stringToNavigationHash(infoBox.title)}-${i+1}`;
        
          return <article 
            key={hash}
            id={hash}
            style={{
              '--bg-color' : isEven 
                ? rgbToString(hexToRgb(color), .9) 
                : rgbToString(hexToRgb(color), .4),
              '--text-color' : isEven
                ? getContrastColor(hexToRgb(color))
                : 'black'
            } as any}
          >
            <div className={classNames("max-50", styles.text_box)}>
              <h2>{infoBox.title}</h2>
              {renderBlockContainers(infoBox.content)}
              <br/><br/>
              {infoBox.buttonLabel && <Link className={styles.button} to={`/organizer/${organizerId}/page/${infoBox.url}`}>{infoBox.buttonLabel}</Link>}
            </div>
          </article>
        })}
      </div>}
    </>
  }


  return <>
    <Header organizer={organizer} forceLogo={!!(invitationId||pageurl)} />
    <div className="main-content">
      {error
      ? <PageError error={error} />
      : <>
          <PageLoading 
            label={invitationId ? T('Laddar event') : T('Laddar events')} 
            loading={loading}
          />
          {!loading && <div style={{display: 'flex', flexDirection: 'column'}}>
            <TwistedHeroTopTwo organizer={organizer} title={organizer?.name || ''} actionButtons={actionButtons} />
            {contentToShow}
          </div>}
        </>}
      </div>
    </>;
}


function renderBlockContainers(bcs : BlockContainer[]) {
  return bcs.map((bc, i) => renderBlocks(bc.blocks, i))
}
function renderBlocks(blocks: Block[], key: number) {
  return blocks.map((block, i) => {
    switch(block.type) {
      case 'html':
        if (block.props.content.startsWith("<p>")) {
          const parser = new DOMParser();
          const doc = parser.parseFromString(block.props.content, 'text/html');
          const onlyNode = doc.body.children.item(0);
          // Return onlyNode as jsx
          const HtmlTag = onlyNode!.tagName as keyof JSX.IntrinsicElements;
          return <HtmlTag key={i} dangerouslySetInnerHTML={{__html: onlyNode!.innerHTML}} />
        } else {
          const HtmlTag = (block.props.tag || 'p') as keyof JSX.IntrinsicElements; 
          return <HtmlTag key={i} dangerouslySetInnerHTML={{__html: block.props.content}} />
        }
      case 'raw':
        const RawTag = (block.props.tag || 'div') as keyof JSX.IntrinsicElements; 
        return <RawTag key={i}>{block.props.content}</RawTag>
      case 'image':
        return <img key={i} src={block.props.img.url} alt={block.props.caption} />
      case 'youtube':
        return <iframe key={i} src={`https://www.youtube.com/embed/${block.props.code}`} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
      case 'table':
        return <table key={i}>
          <tbody>
            {block.props.rows.map((row, i) => <tr key={i}>
              {row.cells.map((cell, i) => <td key={i}>
                {cell.blocks && renderBlocks(cell.blocks, i)}
                {cell.block && renderBlocks([cell.block], i)}
              </td>)}
            </tr>)}
          </tbody>
        </table>
    }
  })
}