import { CSSProperties } from 'react';
import styles from './ButtonsWrapper.module.scss';
import classNames from 'classnames';

type Props = {
  children: React.ReactNode,
  CSSstyles?: CSSProperties,
  className?: string
}

export default function ButtonsWrapper({children, CSSstyles, className} : Props) {
  
  return <div style={CSSstyles} className={classNames(styles.buttons, className)}>
    {children}
  </div>
}