import { useFormatDate } from "cupman-utils/lib/LangContext";

export function useDateFormatStartEnd () {

  const formatDate = useFormatDate();

  return ({startDate, endDate} : {startDate: number | string, endDate: number | string}) => {

    const startDateMs = typeof startDate === 'number'
      ? startDate
      : Date.parse(startDate)

    const endDateMs = typeof endDate === 'number'
      ? endDate
      : Date.parse(endDate)

    const startYear = formatDate(startDateMs, 'yyyy');
    const endYear = formatDate(endDateMs, 'yyyy');

    const startMonth = formatDate(startDateMs, 'MMMM');
    const endMonth = formatDate(endDateMs, 'MMMM');

    const startDateNumber = formatDate(startDateMs, 'd');
    const endDateNumber = formatDate(endDateMs, 'd');

    if(startYear === endYear) {
      // Samma år
      if(startMonth === endMonth) {
        // och samma månad
        if(startDateNumber === endDateNumber) {
          // Och samma dag
          return `${startDateNumber} ${startMonth}, ${startYear}`
        } else {
          // Samma år och månad, men inte samma datum-nummer
          return `${startDateNumber} - ${endDateNumber} ${endMonth},  ${endYear}`
        }
      } else {
        // Samma år men inte samma månad
        return `${startDateNumber} ${startMonth} - ${endDateNumber} ${endMonth}, ${endYear}`
      }
    } else {
      // Olika år
      return `${startDateNumber} ${startMonth}, ${startYear} - ${endDateNumber} ${endMonth}, ${endYear}`
    }
  }
}