import { FormEvent, useEffect, useRef, useState } from "react";
import ReactModal from "react-modal"
import styles from './SearchButtonAndModal.module.scss'; 
import { useTranslations } from "cupman-utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose, faMagnifyingGlass as faMagnifyingGlassLight } from "@fortawesome/pro-light-svg-icons";
import { faMagnifyingGlass as faMagnifyingGlassRegular } from "@fortawesome/pro-regular-svg-icons";
import ButtonLinkHeader from "../ButtonLink/ButtonLinkHeader";
import classNames from "classnames";

type Props = {
  inverted?: boolean,
  showTextOnMobile?: boolean
}

export default function SearchButtonAndModal ({inverted, showTextOnMobile} : Props) {

  const [modalOpen, setModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [infoText, setInfoText] = useState<undefined | string>(undefined)
  const T = useTranslations('cupinvite');
  const inputRef = useRef<null | HTMLInputElement>(null)


  const closeModal = () => {
    setModalOpen(false);
  }

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    if(searchQuery.length > 0) {
      const url = `https://www.cupinvite.com#?query=${encodeURIComponent(searchQuery)}`;
      window.location.href = url;
    } else {
      setInfoText(T('Fyll i ett sökvärde och sök igen'))
    }
  }

  return <>
    <ButtonLinkHeader 
      onClick={() => setModalOpen(true)} 
      inverted={inverted}
    >
      <FontAwesomeIcon icon={faMagnifyingGlassRegular}/> <span className={classNames(styles.search_btn_text, showTextOnMobile && styles.always_show)}>{T('Sök')}</span>
    </ButtonLinkHeader>
    
    <ReactModal
      isOpen={modalOpen}
      onRequestClose={closeModal}
      ariaHideApp={false}
      onAfterOpen={() => inputRef.current?.focus()}
    >

      <button onClick={closeModal} className={styles.close_button}>
        <FontAwesomeIcon icon={faClose} className={styles.close_icon} /> <span>{T('Stäng')}</span>
      </button>
      <div className={styles.search_wrapper}>
        <form onSubmit={(e) => onSubmit(e)} className={styles.search_form}>

          <label htmlFor="search-input">
            {T('Sök turneringsnamn')}
          </label>

          <div className={styles.search_input_container}>
            <FontAwesomeIcon icon={faMagnifyingGlassLight} className={styles.search_icon} />
            <input type="text" id="search-input" role="search" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} ref={inputRef} />
          </div>

          {infoText && <p>{infoText}</p>}

          <button className={styles.search_button}>
            {T('Sök')}
          </button>
        </form>
      </div>

    </ReactModal>
  </>
} 