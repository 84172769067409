import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { EventAdress } from "../../schema"
import { faMapMarkerAlt, faRoute } from "@fortawesome/pro-solid-svg-icons"
import styles from './LocationAndDistance.module.scss'; 
import { useGetDistanceToInKmString } from "../../hooks/useGetDistanceToInKmString";

type Props = {
  address? : EventAdress | undefined
}

export default function LocationAndDistance ({address} : Props) {

  const getDistanceToInKm = useGetDistanceToInKmString();
  const kmDistance = address && getDistanceToInKm({latitude: address.lat, longitude: address.lng})

  return address 
    ? <div className={styles.location_data}>
      <span className="truncate">
        <FontAwesomeIcon icon={faMapMarkerAlt} /> {address.city}, {address.nation}
      </span>
      <span className="truncate">
        <FontAwesomeIcon icon={faRoute} /> {kmDistance}
      </span>
    </div>
    : <div className={styles.location_data}></div>
}