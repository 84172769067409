import styles from './ClubInformationContent.module.scss'; 
import ButtonsWrapper from "../ButtonsWrapper/ButtonsWrapper";
import { SuperinviteEvent } from '../../schema';
import { hexToRgb, rgbToString } from '../../utils/colors';

type Props = {
  invitation: SuperinviteEvent,
  actionButtons?: React.ReactNode[]
}

export default function ClubInformationContent ({invitation, actionButtons} : Props) {


  return <div className={styles.info_container} style={{'--bg-color' : rgbToString(hexToRgb(invitation.organizerColor), .2)} as any}>
    <h1>{invitation.club_name}</h1>
    <ButtonsWrapper>
      {actionButtons}
    </ButtonsWrapper>
  </div>
}