import { useEffect, useState } from 'react';
import styles from './PageLoading.module.scss'; 
import classNames from 'classnames';

type Props = {
  label: string,
  loading: boolean
}

export default function PageLoading({label, loading} : Props) {

  const timeout = 400;
  const [loadingClassNames, setLoadingClassNames] = useState(styles.loading_container);

  useEffect(() => {
    if(loading) {
      document.body.classList.add('no_overflow');
      setLoadingClassNames(styles.loading_container);
    } else {
      setLoadingClassNames(classNames(styles.loading_container, styles.done_enter))
      setTimeout(() => {
        setLoadingClassNames(classNames(styles.loading_container, styles.done_exit))
        document.body.classList.remove('no_overflow');
      }, timeout);
    }

  }, [loading])

  return <div className={loadingClassNames} style={{'--timeout' : `${timeout}ms`} as any}>
    <div className="loading-bottom"></div>
    <div className={styles.inner_container}>
      <img src="https://static.cupmanager.net/images/cupinvite/loading-balls.gif" alt=''/>
      <div className={styles.label}>{label}</div>
    </div>
  </div>
}