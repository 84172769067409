import { useDateFormatStartEnd } from '../../hooks/useDateFormatStartEnd';
import { hexToRgb, rgbToString } from '../../utils/colors';
import MiddleEllipsisSpan from '../MiddleEllipsisSpan/MIddleEllipsisSpan';
import styles from './EventCard.module.scss'; 

type Props = {
  backgroundImage?: string,
  color: string | undefined,
  eventImage: string,
  eventName: string,
  startTime: number | string,
  endTime: number | string
}

export default function CardImageTop ({backgroundImage, color, eventImage, eventName, startTime, endTime} : Props) {

  const formatStartEndDate = useDateFormatStartEnd()

  return <div className={styles.image_container} style={{
    '--event-background' : backgroundImage && `url(${backgroundImage})`,
    '--bg-color' : color 
      ? rgbToString(hexToRgb(color), .3) 
      : 'rgb(var(--color-tag-purple), .3)'
  } as any}>
    <div className={styles.darken}>
      <div className={styles.backdrop_layer}>
        <div className={styles.card_img_wrapper}>
          <img src={eventImage} className={styles.invitation_img} alt={eventName && eventName} />
        </div>
        <div className={styles.image_text_container}>
          <h2 className={styles.card_top_header}>
            <MiddleEllipsisSpan text={eventName && eventName} />
          </h2>
          <p className={styles.image_date}>{formatStartEndDate({startDate: startTime, endDate: endTime})}</p>
        </div>
      </div>
    </div>
  </div>
}