import classNames from 'classnames';
import styles from './MiddleEllipsisSpan.module.scss';

type Props = {
  text:string,
  className?:string,
  inline?:boolean
}

export default function MiddleEllipsisSpan({text, className, inline}: Props) {

  var lastSpace = text.lastIndexOf(' ');
  const nextLastSpace = lastSpace > 0 ? text.substring(0,lastSpace).lastIndexOf(' ') : -1;
  
  
  if( nextLastSpace > 0 && text.length - nextLastSpace < 7 ){
    lastSpace = nextLastSpace;
  }

  if( text.length - lastSpace > lastSpace && text.length - lastSpace > 2 ){
    lastSpace = 0;
  }

  const beginning = lastSpace > 0 ? text.substring(0,lastSpace) : text;
  const end = lastSpace > 0 ? text.substring(lastSpace+1) : undefined;


  return <span className={classNames(styles.container, className, inline && styles.inline)}>
    <span className={styles.beginning}>{beginning}</span>
    {end && <span className={styles.end}>{end}</span>}
  </span>
}
