import { useEffect } from "react"

export const useClickOutside = ({ref, outerRef, callback} : {
  ref: React.MutableRefObject<null | HTMLElement>,
  outerRef?: React.MutableRefObject<null | HTMLElement>,
  callback: () => void
}) => {

  useEffect(() => {
    const checkIfClickedOutside = (e:any) => {
      if(outerRef) {
        if (ref.current && !(ref.current as any).contains(e.target) && !(outerRef.current as any).contains(e.target)) {
          callback()
        }
      } else {
        if (ref.current && !(ref.current as any).contains(e.target)) {
          callback()
        }
      }
    }

    document.addEventListener("click", checkIfClickedOutside)

    return () => {
      document.removeEventListener("click", checkIfClickedOutside)
    }
  }, [callback, outerRef, ref])
}