export function groupBy<T>(items: T[], groupBy: (t:T) => string) {
  return items.reduce((map, t) => {
    const key = groupBy(t);
    const current = map[key] || [] as T[];
    return {
      ...map,
      [key] : [
        ...current,
        t
      ]
    }
  },{} as Record<string,T[]>)
}