import { useEffect, useState } from 'react';
import styles from './Header.module.scss'; 
import SearchButtonAndModal from '../SearchButtonAndModal/SearchButtonAndModal';
import classNames from 'classnames';
import { Organizer } from '../../schema';
import OrganizerLogo from '../OrganizerLogo/OrganizerLogo';
import invitesports_white from '../../images/invitesports_white.svg';
import { OrganizerMenu } from './OrganizerMenu';

export default function Header ({organizer, forceLogo} : {organizer?: Organizer, forceLogo?:boolean}) {
  
  const [scrolled, setScrolled] = useState(window.scrollY > 30)

  useEffect(() => {

    const handleScroll = () => {
      if(window.scrollY > 100) {
        setScrolled(true)
      } else {
        setScrolled(false)
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }

  }, [])


  return <>
    <div className={classNames(styles.header_container, scrolled && styles.scrolled)}>
      <div style={{flex:1}}>
        <a href="https://www.invitesports.com" className={styles.logo}>
          <img src={invitesports_white} alt="To invitesports.com" />
        </a>
      </div>
      {organizer && (scrolled || forceLogo) && <OrganizerLogo logoUrl={organizer.logoImageUrl} name={organizer.name} linkTo={`/organizer/${organizer.id}`} sizePx={40} />}
      <div style={{flex:1}}>
        {organizer && <OrganizerMenu organizer={organizer} />}
      </div>
    </div>

    {/* Just filling out the space */}
    {/* <div style={{height: `${height}px`, background: 'black', position: 'relative', zIndex: 1}}></div> */}
  </>
}