type colorProps = {
  r: number,
  g: number,
  b: number;
}

export function hexToRgb(hex: string) {
  // Remove '#' if present
  hex = hex.replace('#', '');
  
  // Parse hex to RGB
  var bigint = parseInt(hex, 16);
  var r = (bigint >> 16) & 255;
  var g = (bigint >> 8) & 255;
  var b = bigint & 255;
  
  return {r,g,b};
}

export function rgbToString(rgb: colorProps,
alpha?:number) {

  if (alpha) {
    return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, ${alpha})`;
  } else {
    return `rgb(${rgb.r}, ${rgb.g}, ${rgb.b})`
  }
}

export function getContrastColor({r, g, b} : colorProps) {
  const luminance = (0.2126 * r + 0.7152 * g + 0.0722 * b) / 255;

    const midpointLuminance = 0.5;

    if (luminance > midpointLuminance) {
        return 'black';
    } else {
        return 'white';
    }
}




function hue2rgb(p:number, q:number, t:number) {
  if (t < 0) t += 1;
  if (t > 1) t -= 1;
  if (t < 1/6) return p + (q - p) * 6 * t;
  if (t < 1/2) return q;
  if (t < 2/3) return p + (q - p) * (2/3 - t) * 6;
  return p;
}

export const getRGB = ({h, s, l} : {h:number,s:number,l:number}) => {
  var r, g, b;

  h /= 360.0;
  s /= 100.0;
  l /= 100.0;


  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    var p = 2 * l - q;

    r = hue2rgb(p, q, h + 1/3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1/3);
  }

  return {r:Math.round(r * 255),g:Math.round(g * 255),b:Math.round(b * 255)};
}

export const getHSL = ({r, g, b} : colorProps) => {

  r /= 255.0;
  g /= 255.0;
  b /= 255.0;

  const min = Math.min(r, g, b);
  const max = Math.max(r, g, b);

  const lightness = (max+min)/2;
  const delta = (max-min)/2;

  if (min === max) {
      return {h: 0, s:0, l:Math.round(lightness*100)};
  }

  let hue = 0;

  if (max === r) {
      hue = (g - b) / (max - min);

  } else if (max === g) {
      hue = 2 + (b - r) / (max - min);

  } else {
      hue = 4 + (r - g) / (max - min);
  }

  hue = hue * 60;
  if (hue < 0) hue = hue + 360;

  const saturation = delta > 0.5 ? (max - min) / (2 - max - min) : (max - min) / (max + min)

  return {h:Math.round(hue),s:Math.round(saturation*100),l:Math.round(lightness*100)};
}

function adjustGamma(p:number) {
  if (p <= 0.03928) {
      return p / 12.92;
  } else {
      return Math.pow( ( p + 0.055 ) / 1.055, 2.4 );
  }
}

function relativeLuminance(rgb:colorProps) {
  const r = adjustGamma( rgb.r / 255 );
  const g = adjustGamma( rgb.g / 255 );
  const b = adjustGamma( rgb.b / 255 );
  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
}

function contrastRatio(rl1:number,rl2:number) {
  const ratio = (rl1 + 0.05) / (rl2 + 0.05);
  return ratio >= 1 ? ratio : 1 / ratio;
}

export function contrastColor(rgb:colorProps, ratio:number) {
  const rl_white = relativeLuminance({r:255,g:255,b:255});

  const hsl = getHSL(rgb);

  if( contrastRatio(rl_white, relativeLuminance(getRGB({h:hsl.h, s:hsl.s, l:hsl.l}))) > ratio ){
    /*//console.log("Too dark colors, increasing brightness to find contrast")
    
    let inc = -0.01;
    let l2 = ( ( rl_white + 0.05 ) / ratio - 0.05 );
    
    if (l2 < 0) {
        l2 = ( ratio * ( rl_white + 0.05 ) - 0.05 );
        inc = -inc;
    }
    
    for (let i=0; i < 100; i++) {
      l2 += inc;
      if(contrastRatio(rl_white, relativeLuminance(getRGB({h:hsl.h, s:hsl.s, l:l2*100}))) > ratio){
        // one step back
        l2 -= inc;
        //console.log("found contrast")
        break;
      }
    }

    return getRGB({h:hsl.h, s:hsl.s, l:l2*100});*/

    return rgb;
  } else {

    let inc = 0.01;
    let l2 = ( ( rl_white + 0.05 ) / ratio - 0.05 );
    
    if (l2 < 0) {
        l2 = ( ratio * ( rl_white + 0.05 ) - 0.05 );
        inc = -inc;
    }
    
    for (let i=0; i < 100; i++) {
      l2 += inc;
      if(contrastRatio(rl_white, relativeLuminance(getRGB({h:hsl.h, s:hsl.s, l:l2*100}))) < ratio){
        //console.log("found contrast")
        break;
      }
    }

    return getRGB({h:hsl.h, s:hsl.s, l:l2*100});
  }
}