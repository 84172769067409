import classNames from 'classnames';
import styles from './OrganizerLogo.module.scss'; 
import { Link } from 'react-router-dom';

type Props = {
  logoUrl: string | undefined, 
  name: string,
  linkTo: string,
  sizePx: number
}

export default function OrganizerLogo ({logoUrl, name, sizePx, linkTo} : Props) {
  return logoUrl 
    ? <div className={classNames(styles.logo_container, logoUrl && styles.cm_style)} style={{'--size' : `${sizePx}px`} as any}>
      <Link to={linkTo}>
        <img src={logoUrl} alt={name} />
      </Link>
    </div>
    : null
}