import classNames from "classnames";
import styles from './EventCard.module.scss'; 
import MiddleEllipsisSpan from "../MiddleEllipsisSpan/MIddleEllipsisSpan";
import EventTypeTag from "../EventTypeTag/EventTypeTag";
import { useTranslations } from "cupman-utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { EventType } from "../../schema";
import { faShield } from "@fortawesome/pro-solid-svg-icons";


type Props = {
  opened: boolean,
  title: string,
  organizer: string,
  type?: EventType
}

export default function CardHeaderTagAndOrganizer ({title, opened, organizer, type}: Props) {

  const T = useTranslations('cupinvite');

  return <p className={styles.card_meta_data}>
      <EventTypeTag type={type} />
      <span className={classNames('truncate', styles.organizer_name)}>
        <FontAwesomeIcon icon={faShield}/> {organizer}
      </span>
  </p>
}