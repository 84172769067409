import { useTranslations } from "cupman-utils";
import { FetchError } from "../../hooks/useFetchedData";
import styles from './PageError.module.scss'; 
import SearchButtonAndModal from "../../components/SearchButtonAndModal/SearchButtonAndModal";
import ButtonLinkHeader from "../../components/ButtonLink/ButtonLinkHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHouse, faRotateRight } from "@fortawesome/pro-regular-svg-icons";

type Props = {
  error : Error
}

export default function PageError ({error} : Props) {
  const T = useTranslations('cupinvite');

  document.body.classList.add('no_overflow');

  return <div 
    className={styles.error_container} 
    style={{'--background-image' : 'url("//static.cupmanager.net/images/cupinvite/img/frustrated_players.webp?v=2")'} as any}
  >
    <div className={styles.error_info}>
      <h1>{T('Åh nej!')}</h1>
    {(error instanceof FetchError && error.status === 404) 
      ? <>
        <p>{T('Sidan verkar inte finnas.')}</p>
        <p>{T('Har du fått fel länk?')}</p>
        <div className={styles.buttons}>
          <ButtonLinkHeader to='/' inverted>
            <FontAwesomeIcon icon={faHouse}/> {T('Till startsida')}
          </ButtonLinkHeader> <SearchButtonAndModal inverted showTextOnMobile />
        </div>
      </> 
      : <>
        <p>{T('Något verkar gått fel. Testa att ladda om sidan.')}</p>
        <div className={styles.buttons}>
          <ButtonLinkHeader onClick={() => window.location.reload()} inverted>
            <FontAwesomeIcon icon={faRotateRight}/> {T('Ladda om sidan')}
          </ButtonLinkHeader>
          <ButtonLinkHeader to='/' inverted>
            <FontAwesomeIcon icon={faHouse}/> {T('Till startsida')}
          </ButtonLinkHeader>
        </div>
      </>}
    </div>

  </div>
}