import styles from './ButtonLinkHeader.module.scss';
import { Link } from "react-router-dom";
import { ReactNode } from "react";
import classNames from 'classnames';

type Props = {
  onClick?: () => void,
  to?: string,
  children: ReactNode | string,
  inverted?: boolean
}

export default function ButtonLinkHeader ({onClick, children, to, inverted} : Props ) {

  return onClick
      ? <button onClick={onClick} className={classNames(styles.button, inverted && styles.inverted)}>
        {children}
      </button>
      : to
        ? <Link to={to} className={classNames(styles.button, inverted && styles.inverted)}>
          {children}
        </Link>
        : <>You need to add prop 'onClick' or 'to'</>
}