import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const scrolledPositions : Record<string,number> = {};

export function useScrollOnBackForward() {

  window.history.scrollRestoration = 'manual';

  const location = useLocation();

  useEffect(() => {
    const pos = scrolledPositions[location.key] || 0;
    
    setTimeout(() => {
      window.scrollTo({
        left: 0,
        top: pos
      })
    }, 0);

  }, [location]);

  useEffect(() => {
    function onScroll() {
      scrolledPositions[location.key] = window.scrollY;
    }

    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    }
  })

  return null;
}