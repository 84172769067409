import { Link } from "react-router-dom";
import styles from './ButtonLinkHero.module.scss'; 
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import React, { MouseEvent } from 'react';

type Props = {
  children: React.ReactNode,
  to: string,
  icon?: IconDefinition,
  ellipsis?: boolean,
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void,
  colors?: {
    background: string | undefined,
    color: string | undefined
  } | undefined
}

export default function ButtonLinkHero ({children, to, icon, ellipsis, onClick, colors} : Props) {

  return <Link 
    to={to} 
    onClick={onClick} 
    className={classNames(styles.button_link, icon && styles.got_icon, ellipsis && 'truncate')}
    style={{
      '--bg-color' : colors?.background 
        ? colors?.background 
        : 'rgb(var(--color-gray-9))',
      '--color' : colors?.color 
        ? colors?.color 
        : 'white'
    } as any}
  >
    {icon && <span className={styles.icon_container}>
      <FontAwesomeIcon icon={icon}/>
    </span>}
    <span className={styles.label}>{children}</span>
  </Link>
}