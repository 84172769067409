import { useTranslations } from "cupman-utils";
import { useFormatDate } from "cupman-utils/lib/LangContext";
import { faCalendar, faCalendarCircleExclamation, faCalendarDays, faCartPlus, faEnvelope,  faMobileNotch, faShield } from "@fortawesome/pro-regular-svg-icons";
import styles from './InvitationCard.module.scss'; 
import { useDateFormatStartEnd } from "../../hooks/useDateFormatStartEnd";
import ButtonLink from "../ButtonLink/ButtonLink";
import { SuperinviteEvent } from "../../schema";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EventTypeTag from "../EventTypeTag/EventTypeTag";
import { faMapMarkerAlt, faRoute } from "@fortawesome/pro-solid-svg-icons";
import CardImageTop from "../EventCard/CardImageTop";
import CardHeaderTagAndOrganizer from "../EventCard/CardHeaderAndTag";
import LocationAndDIstance from "../EventCard/LocationAndDIstance";



export default function InvitationCard ({invitation} : {invitation: SuperinviteEvent}) {
  const T = useTranslations('cupinvite');

  const showContact = invitation.contact_person || invitation.contact_person_email || invitation.contact_person_number;
  const iconToShow = invitation.contact_person_number
    ? faMobileNotch
    : faEnvelope

  const preTrimmedShowUrl = invitation.website.replace(/(^\w+:|^)\/\//, '').replace('www.', '');
  const trimmedShowUrl = preTrimmedShowUrl.substring(0, preTrimmedShowUrl.indexOf('/'))

  const formatStartEndDate = useDateFormatStartEnd();
  const formatDate = useFormatDate();

  

  return <div className={styles.invitation_card}>
    <CardImageTop
      backgroundImage={invitation.heroImageUrl}
      eventImage={invitation.logo}
      color={invitation.organizerColor}
      startTime={invitation.start_date}
      endTime={invitation.end_date}
      eventName={invitation.name}
    />
    <CardHeaderTagAndOrganizer 
      title={invitation.name} 
      opened
      organizer={invitation.club_name}
      // Type can be sent here
    />
    <div className={styles.inner_container}>
      {showContact && <>
        <h3 className={styles.card_section_header} style={{marginTop: '1rem'}}>
          <FontAwesomeIcon icon={iconToShow}/> {T('Kontakt')}
        </h3>
        <div className={styles.contact_person}>
          {invitation.contact_person && <div aria-description={T('Namn')}>
            <span>{invitation.contact_person}</span>
          </div>}
          {invitation.contact_person_number && <div aria-description={T('Telefon')}>
            <a href={`tel: ${invitation.contact_person_number}`} className={'truncate body_link'}>{invitation.contact_person_number}</a>
          </div>}
          {invitation.contact_person_email && <div aria-description={T('Epost')}>
            <a href={`mailto: ${invitation.contact_person_email}`} className={'truncate body_link'}>{invitation.contact_person_email}</a>
          </div>}
          {invitation.website && <div aria-description={T('Epost')}>
            <a href={invitation.website} className={'truncate body_link'}>{trimmedShowUrl}</a>
          </div>}
        </div>
      </>}

      <div className={styles.dates}>
        <h3 className={styles.card_section_header}><FontAwesomeIcon icon={faCalendarDays}/> {T('Datum')}</h3>
        <div style={{marginTop: '.5rem'}}>{formatStartEndDate({startDate: invitation.start_date, endDate: invitation.end_date})}</div>
      </div>
      <h3 className={styles.card_section_header} style={{color: "rgb(210, 0, 0)"}}>
        <FontAwesomeIcon icon={faCalendarCircleExclamation} /> {T('Sista anmälning')}
      </h3>
      <div className={styles.contact_website}>
        {formatDate(Date.parse(invitation.reg_end_date), 'd MMMM, yyyy')}
      </div>

      <div className={styles.order_button_wrapper}>
        <ButtonLink 
          to={invitation.url+'/step/products'} 
          icon={faCartPlus} 
          block 
          noScale
          centerContent
          
        >
          {T('Välj produkter')}
        </ButtonLink>
      </div>
    </div>
  </div>
}