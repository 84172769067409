import { Link, NavLink } from "react-router-dom";
import { MenuItem, Organizer } from "../../schema";
import styles from './OrganizerMenu.module.scss';
/*

export type Organizer = {
  actionButtons: HeroActionButton[],
  boxes: OrganizerContentBox[],
  startPageContent: BlockContainer[],
  id: number,
  name: string,
  heroImageUrl: string | undefined,
  color: string | undefined,
  logoImageUrl: string | undefined,
  heroContent: string | undefined,
  cards: Card[],

  menu?: MenuItem[]
}
export type MenuItem = {
  title: string,
  href: string,
  type: 'File'|'Page'|'MenuItem'|'External',
  children: MenuItem[]
}

*/

export function OrganizerMenu({organizer} : {organizer: Organizer}) {
    const firstThreeItems = organizer.menu?.slice(0, 3);
    const restOfItems = organizer.menu?.slice(3);
    const renderLink = (item: MenuItem) => {
        if(item.type == 'Page') {
            return <NavLink className={({isActive}) => isActive?styles.activelink:''} to={`/organizer/${organizer.id}/page/${item.href}`}>{item.title}</NavLink>;
        } else if(item.type == 'MenuItem') {
            return <a>{item.title}</a>;
        } else {
            return <a href={item.href}>{item.title}</a>;
        }
    }
    return <div style={{display:'flex', justifyContent:'flex-end'}}>
        <div className={styles.menu_container}>
            <ul>
                {firstThreeItems?.map(item => <li key={item.title}> 
                    {renderLink(item)}
                    {item.children.length > 0 && <ul>
                        {item.children.map(child => <li key={child.title}>
                            {renderLink(child)}
                        </li>)}
                    </ul>}
                </li>)}
                {restOfItems && restOfItems.length > 0 && <li>
                    <a>More</a>
                    <ul>
                        {restOfItems.map(item => <li key={item.title}>
                            {renderLink(item)}
                            {item.children.length > 0 && <ul>
                                {item.children.map(child => <li key={child.title}>
                                    {renderLink(child)}
                                </li>)}
                            </ul>}
                        </li>)}
                    </ul>
                </li>}
            </ul>
        </div>
        <div className={styles.menu_container_mobile}>
            <input type="checkbox" id="mobile_menu_toggle" />
            
                <span></span>
                <span></span>
                <span></span>
            
            <label htmlFor="mobile_menu_toggle" className={styles.overlay}></label>
            <ul id={styles.menu}>
                {organizer.menu?.map(item => <li key={item.title}> 
                    {renderLink(item)}
                    {item.children.length > 0 && <ul>
                        {item.children.map(child => <li key={child.title}>
                            {renderLink(child)}
                        </li>)}
                    </ul>}
                </li>)}
            </ul>
        </div>
    </div>
}